import React, {useState} from 'react';
import MultipleRadioItem from "./MultipleRadioItem";
import {useSelector} from "react-redux";
import {bitStringToBoolArray} from "../../utils";

const RadioList = ({data, onChange, selectedID}) => {
    const {user_info} = useSelector(state => state.userReducer);
    const permissions = bitStringToBoolArray(user_info.details.advisor_permissions);
    const [selectedRadioID, setSelectedRadioID] = useState(selectedID);

    return (
        <>
            {data.map((item, idx) => {
                switch (item) {
                    case 'Add new advisor or admin':
                    case 'Edit advisor or admin':
                    case 'Add new office':
                    case 'Edit office':
                        if (permissions[0] || permissions[2])
                            return (
                                <MultipleRadioItem
                                    label={item.label || item}
                                    key={idx}
                                    isSelected={idx === selectedRadioID}
                                    onClick={() => {
                                        onChange(idx);
                                        setSelectedRadioID(idx);
                                    }}
                                />
                            );
                        else return null;
                    default:
                        return (
                            <MultipleRadioItem
                                label={item.label || item}
                                key={idx}
                                isSelected={idx === selectedRadioID}
                                onClick={() => {
                                    onChange(idx);
                                    setSelectedRadioID(idx);
                                }}
                            />
                        )
                }
            })}
        </>
    );
};

export default RadioList;