import React, {useEffect} from 'react';
import backgroundSrc from '../assets/images/login-background.svg';
import logoSrc from '../assets/images/logo.png';
import warningSrc from '../assets/images/warning.svg';
import Input from "./shared/Input";
import Button from "./shared/Button";
import Row from "./shared/Row";
import {useDispatch, useSelector} from "react-redux";
import {setPage} from "../actions/Navigation";
import {setEmail, setHash, setIfCredentialsCorrect, setPassword} from "../actions/Auth";
import axios from "axios";
import strings from "../strings";
import {setUserInfo} from "../actions/User";
import {PAGE_LANDING} from "./pagesNames";
import unirest from 'unirest'
import { saveAs } from 'file-saver';
const Login = () => {
    const {email, password, areCredentialsCorrect} = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const nextPage = PAGE_LANDING;

    const saveLoginInfoAndLogin = (hash) => {
        dispatch(setHash(hash));
        localStorage.setItem('hash', hash);

        axios.get(strings.apiUrl + 'getUserInfo', {params: {hash}}).then(({data}) => {
            if (data.status === 1) {
                dispatch(setUserInfo(data.user_info));
                dispatch(setPage(nextPage));
            }
        });
    };

    const onEmailChange = e => dispatch(setEmail(e));
    const onPasswordChange = p => dispatch(setPassword(p));
    const onLogin = () => {
        
        axios.get(strings.apiUrl + `loginDash`, {params: {email, password}}).then(({data}) => {
            if (data.status === 1) {
                saveLoginInfoAndLogin(data.token);
            } else {
                dispatch(setIfCredentialsCorrect(false));
            }
        });
        

        
    };

    useEffect(() => {
        if (localStorage['hash']) saveLoginInfoAndLogin(localStorage['hash']);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={'login'}>
            <div className="misc" style={{backgroundImage: `url(${backgroundSrc})`}}>
                <img alt="logo" src={logoSrc} className="logo"/>
            </div>

            <div className="form">
                <div className="form-holder">
                    <span className={'title'}>Welcome</span>
                    <br/>
                    <span className={'subtitle'}>Login to your Direct Mortgages Dashboard</span>

                    <Input
                        label={"Email"}
                        value={email}
                        onChange={onEmailChange}
                        type={"email"}
                    />
                    <Input
                        label={"Password"}
                        value={password}
                        onChange={onPasswordChange}
                        type={"password"}
                    />

                    {!areCredentialsCorrect ? (
                        <Row className={"warning"}>
                            <span className={"text"}>Email or Password is incorrect</span>

                            <img className={"icon"} src={warningSrc} alt="warning"/>
                        </Row>
                    ) : (
                        <div className={"separator"}/>
                    )}

                    <Button onClick={onLogin}>
                        Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;