import {
    FORCE_REMINDERS_FETCH,
    SET_NOTIFICATIONS,
    SET_NOTIFICATIONS_LOADING,
    SET_REMINDERS,
    SET_REMINDERS_LOADING,
    SET_USER_INFO
} from "../actions/types";

const initialState = {
    user_info: {},
    reminders: [],
    reminders_loading: true,
    notifications: [],
    notifications_loading: true,
    remindersRefresh: 0
};

const userReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_USER_INFO:
            return {...state, user_info: payload};
        case SET_REMINDERS:
            return {...state, reminders: payload};
        case SET_NOTIFICATIONS:
            return {...state, notifications: payload};
        case SET_REMINDERS_LOADING:
            return {...state, reminders_loading: payload};
        case SET_NOTIFICATIONS_LOADING:
            return {...state, notifications_loading: payload};
        case FORCE_REMINDERS_FETCH:
            return {...state, remindersRefresh: state.remindersRefresh + 1};
        default:
            return state;
    }
};

export default userReducer;