import {SET_EMAIL, SET_HASH, SET_IF_CREDENTIALS_CORRECT, SET_IS_ADMIN, SET_PASSWORD} from "./types";

export const setEmail = payload => ({
    type: SET_EMAIL,
    payload
});

export const setIsAdmin = payload => ({
    type: SET_IS_ADMIN,
    payload
});

export const setPassword = payload => ({
    type: SET_PASSWORD,
    payload
});

export const setIfCredentialsCorrect = payload => ({
    type: SET_IF_CREDENTIALS_CORRECT,
    payload
});

export const setHash = payload => ({
    type: SET_HASH,
    payload
});