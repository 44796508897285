const COLOR_WHITE = '#fff';
const COLOR_CLEAR_BLUE = '#2e5bff';
const COLOR_CLEAR_BLUE_02 = 'rgba(46, 91, 255, 0.2)';
const COLOR_MEDIUM_GREEN = '#33ac2e';

export {
    COLOR_WHITE,
    COLOR_CLEAR_BLUE,
    COLOR_CLEAR_BLUE_02,
    COLOR_MEDIUM_GREEN,
}