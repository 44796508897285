import React, { lazy, Suspense } from 'react';
import {useSelector} from "react-redux";
import {
    PAGE_ADD_NEW_ADVISOR,
    PAGE_ADD_NEW_CLIENT,
    PAGE_APPLICATION_STATUS,
    PAGE_CLIENT_INFO,
    PAGE_DOCUMENTS,
    PAGE_LANDING,
    PAGE_LOGIN,
    PAGE_NOTIFICATIONS,
    PAGE_PROFILE,
    PAGE_REMINDERS,
    PAGE_UNDER_DEVELOPMENT,
    PAGE_DOCUMENT_ESIGN 
} from "./components/pagesNames";
import  Login from "./components/Login";
const Landing   =  React.lazy (()=>(import("./components/Landing")));
const DocumentEsign = lazy(()=>import('./components/EsignDocument'));
const  AddNewAdvisor = lazy(()=>import( "./components/AddNewAdvisor"));
const UserProfile = lazy(()=>import( "./components/UserProfile"));
const ClientInfo = lazy(()=>import( "./components/ClientInfo"));
const ApplicationStatus = lazy(()=>import( "./components/ApplicationStatus"));
const Reminders = lazy(()=>import( "./components/Reminders"));
const Documents = lazy(()=>import( "./components/Documents"));
const Notifications = lazy(()=>import( "./components/Notifications"));
const AddNewClient = lazy(()=>import( "./components/AddNewClient"));
const PageUnderDevelopment = lazy(()=>import( "./components/PageUnderDevelopment"));

function App() {
    const {pageName} = useSelector(state => state.navReducer);
   
    switch (pageName) {
        case PAGE_PROFILE:
            return <Suspense fallback={<div>Loading...</div>}><UserProfile/></Suspense>;
        case PAGE_LANDING:
            return  <Suspense fallback={<div>Loading...</div>}><Landing/></Suspense>;
        case PAGE_CLIENT_INFO:
            return <Suspense fallback={<div>Loading...</div>}><ClientInfo/></Suspense>;
        case PAGE_APPLICATION_STATUS:
            return <Suspense fallback={<div>Loading...</div>}><ApplicationStatus/></Suspense>;
        case PAGE_LOGIN:
            return  <Login/>
        case PAGE_REMINDERS:
            return <Suspense fallback={<div>Loading...</div>}><Reminders/></Suspense>;
        case PAGE_DOCUMENTS:
            return <Suspense fallback={<div>Loading...</div>}><Documents/></Suspense>;
        case PAGE_NOTIFICATIONS:
            return <Suspense fallback={<div>Loading...</div>}><Notifications/></Suspense>;
        case PAGE_ADD_NEW_CLIENT:
            return <Suspense fallback={<div>Loading...</div>}><AddNewClient/></Suspense>;
        case PAGE_UNDER_DEVELOPMENT:
            return <Suspense fallback={<div>Loading...</div>}><PageUnderDevelopment/></Suspense>;
        case PAGE_ADD_NEW_ADVISOR:
            return <Suspense fallback={<div>Loading...</div>}><AddNewAdvisor/></Suspense>;
        case PAGE_DOCUMENT_ESIGN :
            return <Suspense fallback={<div>Loading...</div>}><DocumentEsign/></Suspense>;    
        default:
            return <></>
    }
}

export default App;
