import React from 'react';
import Row from "./Row";

const MultipleRadioItem = ({label, isSelected, onClick}) => {
    return (
        <div onClick={onClick}>
            <Row className={'multipleRadioItem'}>
                <div className={'radio ' + (isSelected ? 'active' : 'inactive')}/>

                <span className="label" style={{textTransform: 'none'}}>{label}</span>
            </Row>
        </div>
    );
};

export default MultipleRadioItem;