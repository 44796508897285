import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./reducers";

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Root = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

/// email - david.corry@direct2mortgages.co.uk pass - 123456
// email =  ranoveria@gmail.com pass -  12345678 live
//http://ng2-d2m.codedrill.xyz/Api/ 
//https://dash.direct2mortgages.co.uk/