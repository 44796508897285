/* Auth */
export const SET_EMAIL = 'SET_EMAIL';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_IF_CREDENTIALS_CORRECT = 'SET_IF_CREDENTIALS_CORRECT';
export const SET_HASH = 'SET_HASH';

/* Navigation */
export const SET_PAGE = 'SET_PAGE';

/* User */
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_REMINDERS_LOADING = 'SET_REMINDERS_LOADING';
export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING';

/* User */
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS';
export const SET_CLIENT_EMAIL = 'SET_CLIENT_EMAIL';
export const SET_CLIENT_EMAIL_CONFIRM = 'SET_CLIENT_EMAIL_CONFIRM';
export const FORCE_REMINDERS_FETCH = 'FORCE_REMINDERS_FETCH';

/* Mortgage */
export const SET_MORTGAGE_DETAILS = 'SET_MORTGAGE_DETAILS';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const SET_APPLICANTS = 'SET_APPLICANTS';
export const SET_APPLICANT_PROPERTY = 'SET_APPLICANT_PROPERTY';
export const SET_MORTGAGE_PROPERTY = 'SET_MORTGAGE_PROPERTY';
export const SET_PROPERTIES_LIST = 'SET_PROPERTIES_LIST';
export const SET_MORTGAGE_ID = 'SET_MORTGAGE_ID';

/* Advisor */
export const SET_ADVISOR_DETAILS = 'SET_ADVISOR_DETAILS';
export const SET_ADVISORS_LIST = 'SET_ADVISORS_LIST';
export const SET_ADMINS_LIST = 'SET_ADMINS_LIST';
export const SET_SELECTED_ADVISOR_ID = 'SET_SELECTED_ADVISOR_ID';

/* Profile */
export const SET_PROFILE_PROPERTY = 'SET_PROFILE_PROPERTY';