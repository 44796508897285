import {
    SET_APPLICANT_PROPERTY,
    SET_APPLICANTS,
    SET_APPLICATION_STATUS,
    SET_MORTGAGE_DETAILS,
    SET_MORTGAGE_ID,
    SET_MORTGAGE_PROPERTY,
    SET_PROPERTIES_LIST
} from "../actions/types";

const initialState = {
    mortgage_id: -1,
    mortgage_details: {},
    application_status: [],
    applicants: [],
    properties: [],
};

const mortgageReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_MORTGAGE_ID:
            return {...state, mortgage_id: payload};
        case SET_MORTGAGE_DETAILS:
            return {...state, mortgage_details: payload};
        case SET_APPLICATION_STATUS:
            return {...state, application_status: payload};
        case SET_APPLICANTS:
            return {...state, applicants: payload};
        case SET_PROPERTIES_LIST:
            return {...state, properties: payload};
        case SET_APPLICANT_PROPERTY:
            let newApplicants = [...state.applicants];
            newApplicants[payload.id] = {
                ...newApplicants[payload.id],
                [payload.propName]: payload.value
            };
            return {...state, applicants: [...newApplicants]};
        case SET_MORTGAGE_PROPERTY:
            let newProperties = [...state.properties];
            newProperties[payload.id] = {
                ...newProperties[payload.id],
                [payload.propName]: payload.value
            };
            return {...state, properties: [...newProperties]};
        default:
            return state;
    }
};

export default mortgageReducer;
