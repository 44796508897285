export const bitStringToBoolArray = (str = '') => {
    const r = [];
    for (let c of str) {
        if (c === "1")
            r.push(true);
        else
            r.push(false);
    }
    return r;
};

export const boolArrayToBitString = arr => {
    let str = '';
    arr.map(b => str += b ? '1' : '0');
    return str;
};