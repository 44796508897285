import {SET_EMAIL, SET_HASH, SET_IF_CREDENTIALS_CORRECT, SET_IS_ADMIN, SET_PASSWORD} from "../actions/types";

const initialState = {
    isLoggedIn: false,
    hash: '',
    email: '',
    password: '',
    isAdmin: false,
    areCredentialsCorrect: true
};

const authReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_EMAIL:
            return {...state, email: payload};
        case SET_IS_ADMIN:
            return {...state, isAdmin: payload};
        case SET_PASSWORD:
            return {...state, password: payload};
        case SET_IF_CREDENTIALS_CORRECT:
            return {...state, areCredentialsCorrect: payload};
        case SET_HASH:
            return {...state, hash: payload};
        default:
            return state;
    }
};

export default authReducer;