import React, {useState} from 'react';
import Row from "./Row";
import Checkbox from "./Checkbox";
import PropTypes from 'prop-types';

const MultipleSelectItem = ({label, isSelected = false}) => {
    const [isItemSelected, selectItem] = useState(isSelected);

    return (
        <div className={'multipleSelectItem'} onClick={() => selectItem(!isItemSelected)}>
            <Row>
                <span className={'label'}>{label}</span>

                <Checkbox isChecked={isItemSelected}/>
            </Row>
        </div>
    );
};

MultipleSelectItem.propTypes = {
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool
};

export default MultipleSelectItem;