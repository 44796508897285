import React, {useState} from 'react';
import PropTypes from 'prop-types';
import arrowDownSrc from '../../assets/images/arrow-down.svg'
import LightBox from "./LightBox";
import MultipleSelectItem from "./MultipleSelectItem";
import RadioList from "./RadioList";
import Row from "./Row";
import moment from "moment";

const getEmptyDays = m => {
    let
        emptyDays = [],
        month = moment(m, 'DD/MM/YYYY').format('M'),
        year = moment(m, 'DD/MM/YYYY').format('Y'),
        startingDayOfMonth = moment(`1/${month}/${year}`, 'D/M/Y').format('LLLL'),
        numOfEmpty = moment(startingDayOfMonth, 'LLLL').format('d')
    ;

    for (numOfEmpty; numOfEmpty > 0; numOfEmpty--) {
        emptyDays.push(1);
    }

    return emptyDays.map(() => (
        <div className="day nohover"> </div>
    ))
};

const Input = (
    {
        label,
        type = 'text',
        className = '',
        data,
        value = '',
        onChange,
        selectedID,
        isChecked,
        checkboxContainerStyle = {},
        onEnterPressed = () => {
        },
        placeholder = label,
        style = {},
        styles
    }
) => {
    const id = label.replace(/ /g, '_').toLowerCase();

    //state
    const
        [sID, setSelectedID] = useState(selectedID),
        [isLightboxOpen, setIsLightboxOpen] = useState(false)
    ;

    if (type === 'date' && value === '') value = moment().format("DD/MM/YYYY");
    const [m, setMoment] = useState(moment(value, 'DD/MM/YYYY'));


    //toggle
    const toggleLightBox = () => {
        setIsLightboxOpen(!isLightboxOpen);
    };

    switch (type) {
        case 'checkbox':
            return (
                <div className={'checkboxContainer ' + className} style={{...checkboxContainerStyle}}>
                    <label className='inputLabel' htmlFor={id}>
                        {label}
                    </label>

                    <label className="switch">
                        <input id={id} type="checkbox" checked={isChecked} onChange={onChange}/>

                        <span className="slider round"/>
                    </label>
                </div>
            );
        case 'radio':
            return (
                <div className={'inputContainer radio ' + className} style={style}>
                    <label className='inputLabel' htmlFor={id}>
                        {label}
                    </label>

                    <input
                        onClick={toggleLightBox}
                        className={'input multiSelect'}
                        type={'text'}
                        id={id}
                        placeholder={placeholder}
                        readOnly={true}
                        onChange={event => onChange(event.target.value)}
                        value={value}
                    />

                    <img src={arrowDownSrc} alt="arrow-down"/>

                    <LightBox
                        title={label}
                        isOpen={isLightboxOpen}
                        onConfirm={() => {
                            toggleLightBox();
                            onChange(sID);
                        }}
                        onBack={toggleLightBox}
                    >
                        <RadioList data={data} onChange={setSelectedID} selectedID={sID}/>
                    </LightBox>
                </div>
            );
        case 'multi-select':
            return (
                <div className={'inputContainer ' + className} style={style}>
                    <label className='inputLabel' htmlFor={id}>
                        {label}
                    </label>

                    <input
                        onClick={toggleLightBox}
                        className={'input multiSelect'}
                        type={'text'}
                        id={id}
                        placeholder={placeholder}
                        readOnly={true}
                        value={value}
                    />

                    <img src={arrowDownSrc} alt="arrow-down"/>

                    <LightBox
                        title={label}
                        isOpen={isLightboxOpen}
                        onConfirm={() => {
                            onChange();
                            toggleLightBox();
                        }}
                        onBack={toggleLightBox}
                    >
                        {data.map((item, idx) => (
                            <MultipleSelectItem key={idx} {...item}/>
                        ))}
                    </LightBox>
                </div>
            );
        case 'none':
            return (
                <div className={'inputContainer ' + className} style={style}>
                    <label className={'inputLabel'} htmlFor={id}>
                        {label}
                    </label>
                </div>
            );
        case 'date':
            const days = [];
            for (let i = 1; i <= m.daysInMonth(); i++) days.push(i);
            const
                add = () => setMoment(moment(m).add(1, 'M')),
                subtract = () => setMoment(moment(m).subtract(1, 'M')),
                setDate = i => setMoment(moment(m).date(i))
            ;

            return (
                <div className={'inputContainer date ' + className} style={style}>
                    <label className='inputLabel' htmlFor={id}>
                        {label}
                    </label>

                    <input
                        onClick={toggleLightBox}
                        className={'input'}
                        type={'text'}
                        id={id}
                        placeholder={placeholder}
                        readOnly={true}
                        onChange={event => {
                            onChange(event.target.value);
                        }}
                        value={value}
                    />

                    <LightBox
                        title={label}
                        isOpen={isLightboxOpen}
                        onConfirm={() => {
                            toggleLightBox();
                            onChange(m.format('DD/MM/YYYY'));
                        }}
                        onBack={toggleLightBox}
                        style={{width: 400}}
                    >
                        <Row>
                            <span className={'arrow'} onClick={subtract}>{'<'}</span>
                            <span>{m.format('MMMM') + ' '}</span>
                            <span className={'arrow'} onClick={add}>{'>'}</span>
                            <input
                                type="number"
                                value={m.year()}
                                onChange={event => setMoment(moment(m).year(event.target.value))}
                                min={1900}
                                style={{textAlign: 'center'}}
                            />
                        </Row>

                        <Row className={'calendar'}>
                            <div className="day nohover">Sun</div>
                            <div className="day nohover">Mon</div>
                            <div className="day nohover">Tue</div>
                            <div className="day nohover">Wed</div>
                            <div className="day nohover">Thu</div>
                            <div className="day nohover">Fri</div>
                            <div className="day nohover">Sat</div>
                        </Row>

                        <Row className={'calendar'}>
                            {getEmptyDays(m)}
                            {days.map((d, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={'day ' + (d === m.date() ? 'active' : '')}
                                        onClick={() => setDate(d)}
                                    >
                                        <span>{d}</span>
                                    </div>
                                )
                            })}
                        </Row>
                    </LightBox>
                </div>
            );
        default:
            return (
                <div className={'inputContainer ' + className} style={style}>
                    <label className={'inputLabel'} htmlFor={id}>
                        {label}
                    </label>

                    <input
                        style={styles}
                        className={'input'}
                        type={type}
                        onKeyPress={e => {
                            if (e.key === 'Enter') onEnterPressed();
                        }}
                        id={id}
                        placeholder={placeholder}
                        onChange={event => onChange(event.target.value)}
                        value={value}
                    />
                </div>
            )
    }
};

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        'text',
        'date',
        'number',
        'email',
        'password',
        'none',
        'checkbox',
        'multi-select',
        'radio'
    ]),
    className: PropTypes.string
};

export default Input;