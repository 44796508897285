import React from 'react';
import Card from "./Card";
import PropTypes from 'prop-types';
import Button from "./Button";
import Row from "./Row";

const LightBox = (
    {
        title,
        children,
        onConfirm,
        onBack,
        isOpen,
        singleButton = false,
        cardClassName = '',
        style = {},
        showButtons = true,
        childrenStyle = {}
    }) => {
    return (
        <>
            {isOpen && (
                <div className={'light-box'}>
                    <div className={"shadow"}>
                        <Card className={'box ' + cardClassName} style={style}>
                            <span className={"title"}>{title}</span>

                            <div className={"children"} style={childrenStyle}>
                                {children}
                            </div>

                            {singleButton && (
                                <Button style={{marginLeft: 'auto', marginRight: 'auto'}} width={380} onClick={onBack}>
                                    Back to dashboard
                                </Button>
                            )}

                            {showButtons && !singleButton && (
                                <Row>
                                    <Button width={140} onClick={onConfirm}>
                                        Confirm
                                    </Button>

                                    <Button width={210} type={'grey'} onClick={onBack}>
                                        Back to dashboard
                                    </Button>
                                </Row>
                            )}
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
};

LightBox.propTypes = {
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    isOpen: PropTypes.bool.isRequired
};

export default LightBox;