import {combineReducers} from "redux";
import authReducer from "./Auth";
import navReducer from "./Navigation";
import userReducer from "./User";
import clientReducer from "./Client";
import mortgageReducer from "./Mortgage";
import advisorReducer from "./Advisor";

const rootReducer = combineReducers({
    authReducer,
    navReducer,
    userReducer,
    clientReducer,
    mortgageReducer,
    advisorReducer
});

export default rootReducer;