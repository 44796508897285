import {SET_PAGE} from "../actions/types";
import {PAGE_LOGIN} from "../components/pagesNames";

const initialState = {pageName: PAGE_LOGIN};

const navReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_PAGE:
            return {...state, pageName: payload};
        default:
            return state;
    }
};

export default navReducer;