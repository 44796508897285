const strings = {
    // apiUrl: 'http://ng2-d2m.codedrill.xyz/Api/',
    // testserver
    apiUrl: 'https://api-ng.direct2mortgages.co.uk/api/',
    imagesUrl: 'https://api-ng.direct2mortgages.co.uk/images/',
    filesUrl: 'https://api-ng.direct2mortgages.co.uk/files/',
    // pdfUrl: 'http://ng2-d2m.codedrill.xyz/',
    // testserver
    pdfUrl: 'https://api-ng.direct2mortgages.co.uk/',
    defaults: {
        defaultAvatarUrl: 'https://picsum.photos/',
        defaultUsername: 'Tracy Cooper',
    },
    header: {
        links: [
            'Reminders',
            'Active chats',
            'Administration',
            'Notifications' 
        ],
        searchBarPlaceholder: 'Search Names, email address...',
        welcomeMessage: 'Hello, ',
        logOutText: 'Logout'
    },
    userProfilePage: {
        pageTitle: 'Staff Details',
        idText: 'Advisor ID ',
        uploadPhoto: 'Upload Photo',
    },
    login: {
        list: 'Advisor: ',
        submit: 'Submit',
        identifyClient: 'Identify Client',
        credsPrompt: 'Please enter client\'s credentials to proceed'
    },
    checklistLabels: [
        'Authorised by client to proceed',
        'Acceptable forms of documentation',
        'Proof of income/Bank statements recieved',
        'Proof of deposit recieved',
        'Decision in principle',
        'Supporting documents recieved',
        'Survey instructed',
        'Mortgage offered',
    ],
    mortgageTypes: [
        'Buy To Let Remortgage',
        'Purchase',
        'Remortgage',
        'Right To Buy',
        'Buy To Let',
        'Limited Company Buy To Let',
        'Let To Buy',
        'HMO',
    ],
    administrationActions: [
        'Add new client',
        'Edit client',
        'Add new advisor or admin',
        'Edit advisor or admin',
        'Add new office',
        'Edit office',
        'Add message template',
        'Edit message template',
        'Send Notification to All Users'

    ],
};

export default strings;
//https://dash.direct2mortgages.co.uk/