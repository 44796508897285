import {
    FORCE_REMINDERS_FETCH,
    SET_NOTIFICATIONS,
    SET_NOTIFICATIONS_LOADING,
    SET_REMINDERS,
    SET_REMINDERS_LOADING,
    SET_USER_INFO
} from "./types";

export const setUserInfo = payload => ({
    type: SET_USER_INFO,
    payload
});

export const setReminders = payload => ({
    type: SET_REMINDERS,
    payload
});

export const setNotifications = payload => ({
    type: SET_NOTIFICATIONS,
    payload
});

export const setRemindersLoading = payload => ({
    type: SET_REMINDERS_LOADING,
    payload
});

export const setNotificationsLoading = payload => ({
    type: SET_NOTIFICATIONS_LOADING,
    payload
});

export const forceRemindersFetch = () => ({
    type: FORCE_REMINDERS_FETCH
});