import {SET_CLIENT_DETAILS, SET_CLIENT_EMAIL, SET_CLIENT_EMAIL_CONFIRM, SET_CLIENT_ID} from "../actions/types";

const initialState = {
    client_id: '',
    client_details: {},
    email: '',
    emailConfirm: ''
};

const clientReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_CLIENT_ID:
            return {...state, client_id: payload};
        case SET_CLIENT_DETAILS:
            return {...state, client_details: payload};
        case SET_CLIENT_EMAIL:
            return {...state, email: payload};
        case SET_CLIENT_EMAIL_CONFIRM:
            return {...state, emailConfirm: payload};
        default:
            return state;
    }
};

export default clientReducer;
