/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {COLOR_CLEAR_BLUE, COLOR_CLEAR_BLUE_02, COLOR_MEDIUM_GREEN, COLOR_WHITE} from "../../styles/colorsJS";
import PropTypes from 'prop-types';
// eslint-disable-next-line
import React from "react";

const Button = (
    {
        children = 'Button',
        type = 'blue',
        width = '',
        height = 40,
        style = {},
        className = ' ',
        onClick = () => {
        }
    }
) => {
    let buttonColor, hoverButtonColor, textColor, hoverTextColor, borderColor, hoverBorderColor;

    switch (type) {
        case 'grey':
            textColor = COLOR_CLEAR_BLUE;
            buttonColor = COLOR_CLEAR_BLUE_02;
            borderColor = COLOR_CLEAR_BLUE_02;

            hoverTextColor = COLOR_CLEAR_BLUE;
            hoverButtonColor = COLOR_CLEAR_BLUE_02;
            hoverBorderColor = COLOR_CLEAR_BLUE;
            break;
        case 'green':
            textColor = COLOR_WHITE;
            buttonColor = COLOR_MEDIUM_GREEN;
            borderColor = COLOR_MEDIUM_GREEN;

            hoverTextColor = COLOR_MEDIUM_GREEN;
            hoverButtonColor = COLOR_WHITE;
            hoverBorderColor = COLOR_MEDIUM_GREEN;
            break;
        default:
            textColor = COLOR_WHITE;
            buttonColor = COLOR_CLEAR_BLUE;
            borderColor = COLOR_CLEAR_BLUE;

            hoverTextColor = COLOR_CLEAR_BLUE;
            hoverButtonColor = COLOR_WHITE;
            hoverBorderColor = COLOR_CLEAR_BLUE;
            break;
    }

    const definedStyle = css`
        width: ${width}px;
        height: ${height}px;
        background-color: ${buttonColor};
        border-color: ${borderColor};
        color: ${textColor};
        
        &:active {
            background-color: ${hoverButtonColor};
            border: 2px solid ${hoverBorderColor};
            color: ${hoverTextColor};
        }
     `;

    return (
        <div css={definedStyle} className={'button ' + className} style={style} onClick={onClick}>
            <span>{children}</span>
        </div>
    )
};

Button.propTypes = {
    type: PropTypes.oneOf([
        'blue',
        'green',
        'grey'
    ]),
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default Button;
