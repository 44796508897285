import React, {useState} from 'react';
import tickSrc from '../../assets/images/tick.svg';
import PropTypes from 'prop-types';

const Checkbox = ({isChecked}) => {
    const [checked, check] = useState(isChecked);

    const click = () => check(!checked);

    return (
        <div className={'checkbox ' + (isChecked ? 'active' : 'inactive')} onClick={click}>
            <img src={tickSrc} alt="tick"/>
        </div>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool
};

export default Checkbox;