export const PAGE_LOGIN = 'PAGE_LOGIN';
export const PAGE_ACTIVE_CHATS = 'PAGE_ACTIVE_CHATS';
export const PAGE_LANDING = 'PAGE_LANDING';
export const PAGE_CLIENT_INFO = 'PAGE_CLIENT_INFO';
export const PAGE_DOCUMENTS = 'PAGE_DOCUMENTS';
export const PAGE_NOTIFICATIONS = 'PAGE_NOTIFICATIONS';
export const PAGE_REMINDERS = 'PAGE_REMINDERS';
export const PAGE_ADD_NEW_CLIENT = 'PAGE_ADD_NEW_CLIENT';
export const PAGE_ADD_NEW_ADVISOR = 'PAGE_ADD_NEW_ADVISOR';
export const PAGE_UNDER_DEVELOPMENT = 'PAGE_UNDER_DEVELOPMENT';
export const PAGE_PROFILE = 'PAGE_PROFILE';
export const PAGE_APPLICATION_STATUS = 'PAGE_APPLICATION_STATUS';
export const PAGE_DOCUMENT_ESIGN = 'PAGE_DOCUMENT_ESIGN '