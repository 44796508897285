import {SET_ADMINS_LIST, SET_ADVISOR_DETAILS, SET_ADVISORS_LIST, SET_SELECTED_ADVISOR_ID} from "../actions/types";

const initialState = {
    advisor_details: {},
    advisors_list: [],
    admins_list: [],
    selected_advisor_id: 1009
};

const advisorReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_ADVISOR_DETAILS:
            return {...state, advisor_details: payload};
        case SET_ADVISORS_LIST:
            return {...state, advisors_list: payload};
        case SET_ADMINS_LIST:
            return {...state, admins_list: payload};
        case SET_SELECTED_ADVISOR_ID:
            return {...state, selected_advisor_id: payload};
        default:
            return state;
    }
};

export default advisorReducer;